<template>
  <v-main>

    <v-container
        id="edit-member-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-account-edit-outline"
          class="px-5 py-3"
          ref="form"
      >

        <v-card-text>

          <div>
            <v-alert
                type="error"
                outlined
                v-for="(error, i) in errors"
                :key="i"
            >
              {{ error }}
            </v-alert>
          </div>

          <v-form ref="memberForm"
                  v-model="accountValid"
                  lazy-validation
                  @submit.prevent="validate"

          >

            <v-row v-if="userId">
              <v-col
                  cols="12"
                  sm="4"
                  class="font-weight-medium"
              >
                Account Number:
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                {{ user.accountNumber }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.firstName"
                    label="First Name"
                    :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.middleName"
                    label="Middle Name/Initial"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.lastName"
                    label="Last Name"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.address"
                    label="Address"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.addressLine2"
                    label="Address line 2 (optional)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.city"
                    label="City"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-select
                    label="State"
                    v-model="computedState"
                    :items="statesList"
                    item-text="name"
                    item-value="code"
                    return-object
                    :rules="rules.required"

                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.postalCode"
                    label="Zip/Postal Code"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-select
                    label="Country"
                    v-model="computedCountry"
                    :items="countriesList"
                    item-text="name"
                    item-value="code"
                    return-object
                    :rules="rules.required"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.phoneNumber"
                    label="Phone Number"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.email"
                    label="Email Address"
                    type="email"
                    :rules="rules.emailAddress"

                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.idNumber"
                    label="ID Number"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-select
                    v-model="user.idType"
                    label="ID Type"
                    :items="idTypes"
                    item-text="name"
                    item-value="id"
                    return-object
                    :rules="rules.required"
                ></v-select>
              </v-col>

            </v-row>

            <v-row>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.idIssuedBy"
                    label="ID Issuing Country/State"
                    :rules="rules.required"

                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.idExpires"
                    label="ID Expiration Date"
                    :rules="rules.idExpiration"
                ></v-text-field>
              </v-col>
            </v-row>


            <v-row>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="user.birthYear"
                    label="Year of Birth"
                    :rules="rules.birthYear"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  sm="4"
              >

                <v-radio-group
                    v-model="user.gender"
                    row
                    :rules="rules.required"
                    label="Gender"
                >
                  <v-radio
                      v-for="(item, i) in genderOptions"
                      :key="i"
                      :label="item"
                      :value="item"
                  >
                  </v-radio>

                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="3"
                sm="2"
              >
                <v-checkbox
                    v-model="user.isMarried"
                    label="Is Married"
                >
                </v-checkbox>
              </v-col>

              <v-col
                  cols="3"
                  sm="2"
              >
                <v-checkbox
                    v-model="user.isSibling"
                    label="Is Sibling"
                >
                </v-checkbox>
              </v-col>

              <v-col
                  cols="12"
                  sm="8"
              >

              </v-col>

            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-select
                    v-model="user.qualification"
                    label="Membership Qualification"
                    :items="qualifications"
                    item-text="description"
                    item-value="id"
                    return-object
                    :rules="rules.required"
                />
              </v-col>
            </v-row>

            <v-row v-if="computedShowRecommenders">
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="recommenders[0].name"
                    v-bind:label="'Recommender 1 Name'"
                    :rules="rules.recommender"
                ></v-text-field>
                <v-text-field
                    v-model="recommenders[0].phoneNumber"
                    label="Recommender 1 Phone Number"
                    :rules="rules.numeric"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="recommenders[1].name"
                    label="Recommender 2 Name"
                    :rules="rules.recommender"
                ></v-text-field>
                <v-text-field
                    v-model="recommenders[1].phoneNumber"
                    label="Recommender 2 Phone Number"
                    :rules="rules.numeric"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="recommenders[2].name"
                    label="Recommender 3 Name"
                    :rules="rules.recommender"
                ></v-text-field>
                <v-text-field
                    v-model="recommenders[2].phoneNumber"
                    v-bind:label="'Recommender 3 Phone Number'"
                    :rules="rules.numeric"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="2"
              >
                Agreed to Bylaws:
              </v-col>
              <v-col
                  cols="12"
                  sm="2"
              >
                <span v-if="user.agreeToTerms">Yes</span><span v-else>No</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                Roles:
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="2"
                  sm="2"
              >
                <v-checkbox
                    v-model="user.roles"
                    label="Member"
                    value="ROLE_MEMBER"
                ></v-checkbox>

              </v-col>

              <v-col
                  cols="2"
                  sm="2"
              >

                <v-checkbox
                    v-model="user.roles"
                    label="Finance"
                    value="ROLE_FINANCE"
                ></v-checkbox>

              </v-col>

              <v-col
                  cols="2"
                  sm="2"
              >

                <v-checkbox
                    v-model="user.roles"
                    label="Support"
                    value="ROLE_SUPPORT"
                ></v-checkbox>

              </v-col>

              <v-col
                  cols="2"
                  sm="2"
              >
                <v-checkbox
                    v-model="user.roles"
                    label="Admin"
                    value="ROLE_ADMIN"
                ></v-checkbox>

              </v-col>

            </v-row>


            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                    v-model="computedJoinedDate"
                    label="Joined Date"
                    type="date"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-select
                    v-model="computedStatus"
                    label="Status"
                    :items="userStatusMap"
                    :rules="rules.required"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="userId">
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-textarea
                    v-model="comment"
                    label="Comment"
                    auto-grow
                    rows="1"
                    hide-details="auto">
                </v-textarea>

              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <div class="my-2">
                  <v-btn
                      color="primary"
                      dark
                      type="submit"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-col>
            </v-row>

          </v-form>

        </v-card-text>

      </base-material-card>

      <v-expansion-panels
          multiple
          light
          hover
          v-model="expandedPanels"
          v-if="userId"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>Audit Trail</v-expansion-panel-header>

          <v-expansion-panel-content>
            <shared-audit-trail-view :item-id="userId"></shared-audit-trail-view>
          </v-expansion-panel-content>

        </v-expansion-panel>
      </v-expansion-panels>

    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import geoUtils from '@/utils/geography'
import validation from '@/utils/validation'
import constants from '@/utils/constants'
import moment from 'moment-timezone'
import SharedAuditTrailView from "../shared/AuditTrailView";


export default {
  name: 'CoreEditMemberView',
  components: {SharedAuditTrailView},
  created() {
    this.userId = this.$route.params.memberId
    if (this.userId !== undefined) {
      this.fetchMember()
    }
    this.statesList = geoUtils.fetchUsStates()
    this.countriesList = geoUtils.fetchCountries()
    this.fetchIdTypes()
    this.fetchQualifications()
  },

  computed: {
    computedStatus: {
      get() {
        return this.userStatusMap[this.user.status]
      },
      set(value) {
        this.user.status = this.userStatusMap.indexOf(value)
      }
    },
    computedState: {
      get() {
        return geoUtils.findUsStateByCode(this.user.state)
      },
      set(value) {
        this.user.state = value.code
      }
    },
    computedCountry: {
      get() {
        return geoUtils.findCountryByCode(this.user.country)
      },
      set(value) {
        this.user.country = value.code
      }
    },
    computedJoinedDate: {
      get() {
        return moment(this.user.joined).format('YYYY-MM-DD')
      },
      set(value) {
        this.user.joined = value
      }
    },
    computedShowRecommenders: function () {
      return (this.user.qualification !== null) && (this.user.qualification.id === 3)
    }
  },

  watch: {
    errors: function () {
      scroll(0, 0)
    }
  },

  methods: {
    validate() {
      if (this.$refs.memberForm.validate()) {
        if (this.user.id !== undefined) {
          return this.updateUser()
        }
        this.createUser()
      }
    },
    buildPayload() {
      let payload = {
        firstName: this.user.firstName,
        middleName: this.user.middleName,
        lastName: this.user.lastName,
        address: this.user.address,
        addressLine2: this.user.addressLine2,
        city: this.user.city,
        state: this.user.state,
        postalCode: this.user.postalCode,
        country: this.user.country,
        phoneNumber: this.user.phoneNumber,
        email: this.user.email,
        birthYear: this.user.birthYear,
        gender: this.user.gender,
        idNumber: this.user.idNumber,
        idType: this.user.idType.id,
        idExpires: this.user.idExpires,
        idIssuedBy: this.user.idIssuedBy,
        isSibling: this.user.isSibling,
        isMarried: this.user.isMarried,
        joined: moment(this.user.joined).format('YYYY-MM-DD'),
        roles: this.user.roles,
        status: this.user.status,
        qualificationId: this.user.qualification.id,
        comment: this.comment
      }
      payload.idTypeId = this.user.idType.id
      return payload
    },
    fetchIdTypes() {
      api
          .fetchIdTypes()
          .then(response => {
            this.idTypes = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchMember() {
      api
          .fetchUser(this.userId)
          .then(response => {
            this.user = response.data
            this.fetchRecommenders()
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    updateUser() {
      let payload = this.buildPayload()
      api
          .updateUser(this.user.id, payload)
          .then(response => {
            if (response.status === 200) {
              this.user = response.data
              this.saveRecommenders(this.userId)
              if (this.errors.length === 0) {
                this.$router.push(`/member/${this.user.id}/view`)
              }
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    createUser() {
      let payload = this.buildPayload()
      api
          .createUser(payload)
          .then(async response => {
            if (response.status === 201) {
              this.user = response.data
              await this.saveRecommenders(this.user.id)
              if (this.errors.length === 0) {
                await this.$router.push(`/member/${this.user.id}/view`)
              }
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    saveRecommenders(uid) {
      for (const rec of this.recommenders) {
        if (Object.keys(rec).length === 0) {
          continue
        }

        const payload = {
          name: rec.name,
          phoneNumber: rec.phoneNumber
        }
        if (rec.id === undefined) {
          this.createRecommender(uid, payload)
        } else {
          this.updateRecommender(uid, rec.id, payload)
        }
      }
    },
    createRecommender(uid, payload) {
      api
          .createRecommender(uid, payload)
          .catch(err => {
            this.handleError(err)
          })
    },
    updateRecommender(uid, recId, payload) {
      api
          .updateRecommender(uid, recId, payload)
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchQualifications() {
      api
          .fetchQualifications()
          .then(response => {
            this.qualifications = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchRecommenders() {
      api
          .fetchRecommenders(this.user.id)
          .then(response => {
            if (response.data.length) {
              this.recommenders = response.data
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      accountValid: false,
      errors: [],
      userId: null,
      user: {
        qualification: null,
        roles: []
      },
      comment: null,
      genderOptions: ['Male', 'Female', 'Other'],
      userStatusMap: constants.getUserStatusMap(),
      statesList: [],
      countriesList: [],
      idTypes: [],
      qualifications: [],
      auditLogs: [],
      expandedPanels: [0],
      recommenders: [{}, {}, {}],
      rules: {
        emailAddress: [
          v => validation.isEmailAddress(v) || 'must be a valid email address'
        ],
        required: [
          v => validation.isRequired(v) || 'required'
        ],
        numeric: [
          v => validation.isInteger(v) || 'must be a number'
        ],
        birthYear: [
          v => validation.isInteger(v) || 'must be a year number',
          v => validation.isMoreThan(v, 1900) || 'must be after 1900',
          v => validation.isBeforeOrEqualToCurrentYear(v) || 'must be before or equal to current year'
        ],
        idExpiration: [
          v => validation.isInteger(v) || 'must be a year number',
          v => validation.isAfterOrEqualToCurrentYear(v) || 'must be after or equal to current year'
        ],
        recommender: [
          v => validation.isRequired(v) || 'required',
          v => (v && validation.isUnique(v, this.recommenders.map(el => el.name))) || 'must be unique'
        ]
      }
    }
  }
}
</script>

<style lang="sass">
.v-expansion-panel-header
  font-size: 1.15em

th
  vertical-align: top

th.audit-date
  width: 15%
th.audit-user
  width: 10%

</style>
